import { Controller } from "stimulus";
import EmojiButton from "@joeattardi/emoji-button";

export default class extends Controller {
  static targets = ["button", "input"];

  initialize() {
    this.picker = new EmojiButton();
    this.picker.on("emoji", (emoji) => {
      this.inputTarget.value = emoji;
    });
  }

  openPicker(event) {
    this.picker.togglePicker(event.currentTarget);
  }
}
