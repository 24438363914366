import { Controller } from "stimulus";
import createChannel from "../channels/channel";

export default class extends Controller {
  connect() {
    this.channel = createChannel({
      channel: "PlayerChannel",
      player_id: this.element.dataset.playerId,
    });
  }

  disconnect() {
    if (this.channel) {
      this.channel.unsubscribe();
    }
  }
}
