import { Controller } from "stimulus";
import CodeMirror from "codemirror";
import "codemirror/mode/ruby/ruby";
import "codemirror/mode/python/python";
import "codemirror/addon/edit/closebrackets";
import "codemirror/keymap/sublime";
import Rails from "@rails/ujs";

import throttle from "../helpers/throttle";

export default class extends Controller {
  static targets = ["textarea", "livecode"];

  initialize() {
    this.editor = CodeMirror.fromTextArea(this.element, {
      lineNumbers: true,
      theme: "monokai",
      mode: this.data.get("language"),
      autoCloseBrackets: true,
      tabSize: this.data.get("language") === "ruby" ? 2 : 4,
      indentUnit: this.data.get("language") === "ruby" ? 2 : 4,
      keyMap: "sublime",
      readOnly: this.data.get("readonly"),
    });
    this.editor.setOption("extraKeys", {
      Tab: function (cm) {
        var spaces = Array(cm.getOption("indentUnit") + 1).join(" ");
        cm.replaceSelection(spaces);
      },
    });
    if (this.hasLivecodeTarget) {
      this.editor.on("changes", (e) => {
        this.changeCode(e);
      });
    }
    this.editor.on("beforeChange", function (e, change) {
      if (change.origin == "paste") {
        change.cancel();
        alert("Pasting is Cheating!");
      }
    });

    this.refreshEditor();
  }

  connect() {
    this.refreshEditor();
  }

  refreshEditor() {
    if (this.editor) {
      setTimeout(() => {
        this.editor.refresh();
      }, 100);
    }
  }
  handleKeydown(e) {
    const form = document.getElementById("new_attempt");
    if (
      form &&
      (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
      e.key == "s"
    ) {
      e.preventDefault();
      Rails.fire(form, "submit");
    }
  }

  changeCode(e) {
    let code = e.getValue();
    let playerId = this.element.dataset.playerId;
    throttle(this.sendCode(code, playerId));
  }

  sendCode(code, playerId) {
    fetch(`/players/${playerId}/writing`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: code }),
    });
  }
}
