import { Controller } from "stimulus";
import { confetti } from "dom-confetti";

export default class extends Controller {
  connect() {
    this._confettiAction();
  }

  _confettiAction() {
    const config = {
      angle: "360",
      spread: "360",
      startVelocity: "57",
      elementCount: 50,
      dragFriction: "0.09",
      duration: "5520",
      stagger: 0,
      width: "19px",
      height: "20px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    };
    confetti(this.element, config);
  }
}
