import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this._initToast();
  }

  _initToast() {
    $(this.element).toast("show");
  }
}
