import CableReady from "cable_ready";
import consumer from "./consumer";

const createChannel = (object) => {
  consumer.subscriptions.create(object, {
    received(data) {
      if (data.cableReady) CableReady.perform(data.operations);
    },
  });
};

export default createChannel;
