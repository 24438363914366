import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this._showModal();
  }

  _showModal() {
    $(this.element).modal("show");
  }
}
