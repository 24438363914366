import { Controller } from "stimulus";
import { default as AnsiUp } from "ansi_up";

export default class extends Controller {
  static targets = ["stdout"];

  initialize() {
    const ansi_up = new AnsiUp();
    const ANSIStdout = this.stdoutTarget.innerText;
    const HTMLStdout = ansi_up.ansi_to_html(ANSIStdout);
    this.stdoutTarget.innerHTML = HTMLStdout;
  }
}
