import { Controller } from "stimulus";
import consumer from "../channels/consumer";

import countdown from "../helpers/countdown";
const COUNTDOWN_SECONDS = 3;
const COUNTDOWN_MILLISECONDS = 3200;
export default class extends Controller {
  static targets = [
    "liveRounds",
    "hostRounds",
    "playerCard",
    "notifications",
    "countdown",
    "registration",
  ];

  connect() {
    this.gameChannel = consumer.subscriptions.create(
      {
        channel: "GameChannel",
        game_id: this.element.dataset.gameId,
      },
      {
        received: (data) => {
          if (data.type === "reload_player") {
            this.updatePlayer(data);
          } else if (data.type === "rounds_update") {
            this.updateRounds(data);
          } else if (data.type === "reload_live") {
            if (
              window.location.href ===
              `/games/${this.element.dataset.gameId}/live`
            ) {
              window.location.href = `/games/${this.element.dataset.gameId}/live`;
            }
          } else if (
            data.type === "attempt_passed" &&
            data.player === parseInt(this.element.dataset.playerId, 10) &&
            this.hasNotificationsTarget
          ) {
            this.notificationsTarget.insertAdjacentHTML("beforeend", data.html);
          }
        },
      },
    );
  }

  updatePlayer = (data) => {
    if (data.start_round) {
      this.element.insertAdjacentHTML("beforeend", data.modal);
      countdown(this.countdownTarget, COUNTDOWN_SECONDS);
      setTimeout(() => {
        window.location.href = `/games/${this.element.dataset.gameId}/player`;
      }, COUNTDOWN_MILLISECONDS);
    } else if (data.end_round) {
      this.element.insertAdjacentHTML("beforeend", data.modal);
      $(".modal").on("hidden.bs.modal", function (e) {
        window.location.href = `/games/${this.element.dataset.gameId}/player`;
      });
    }
  };

  updateRounds(data) {
    if (this.hasLiveRoundsTarget) {
      this.liveRoundsTarget.innerHTML = data.html.live;
    } else if (this.hasHostRoundsTarget) {
      this.hostRoundsTarget.innerHTML = data.html.host.host_rounds;
      document.getElementById("navbar-container").innerHTML =
        data.html.host.host_navbar;
    } else if (this.hasRegistrationTarget) {
      this.registrationTarget.innerHTML = data.html.registration;
    }
    if (this.hasPlayerCardTarget && data.html.player_card) {
      let div = this.playerCardTargets.find(
        (m) =>
          parseInt(m.dataset.playerId, 10) === data.html.player_card.player_id,
      );
      div.innerHTML = data.html.player_card.html;
    }
  }

  disconnect() {
    if (this.channel) {
      this.channel.unsubscribe();
    }
  }
}
