import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["runButton"];

  onSubmit() {
    const btn = this.runButtonTarget;
    btn.value = "Running...";
    btn.disabled = true;
  }
}
