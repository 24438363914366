import { Controller } from "stimulus";
import Sortable from "sortablejs";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["list", "selectedLine"];

  connect() {
    this.setSortable();
  }

  setSortable() {
    if (this.hasListTarget) {
      Sortable.create(this.listTarget, {
        filter: ".no-dragging",

        onEnd: (evt) => {
          let input = this.selectedLineTarget;
          input.value = evt.newIndex;
          this.sendSeats(input.value);
        },
      });
    }
  }
  sendSeats(seats) {
    let infoDiv = this.listTarget;
    let game_id = infoDiv.dataset.gameId;
    let round_id = infoDiv.dataset.roundId;
    fetch(`/games/${game_id}/rounds/${round_id}`, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ round: { seats_for_next_round: seats } }),
    }).then(() => window.location.reload(true));
  }
}
